import { useContext } from 'react';
import { ThemeContext } from '../theme/ThemeProvider';

const CommonStyle = () => {
  const { colors, theme } = useContext(ThemeContext);

  const { color_primary, color_secondary, color_primary200 } = colors;

  // Define your CSS styles as an object
  const styles = {
    bgPrimary: {
      backgroundColor: color_primary,
    },

    textPrimary: {
      color: color_secondary
    },

    borderPrimary: {
      borderColor: color_secondary
    },

    buttonOutline: {
      padding: '4px 16px',
      borderWidth: '1px',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      borderStyle: 'solid',
      borderRadius: '5px',
      borderWidth: '1px',
      color: color_secondary,
      borderColor: color_secondary,
    },

    headerBackgroud: {
      backgroundColor: color_primary200,
    }
  };

  // Return the CSS object
  return styles;
};

export default CommonStyle;
