import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import ExplorePage from './pages/ExplorePage/ExplorePage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ExplorePage />}>
          {/* <Route index element={<TestPage />} /> */}
          {/* <Route path="test" element={<TestPage />} /> */}
          {/* <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
