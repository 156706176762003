import { useContext } from 'react';
import { ThemeContext } from '../../theme/ThemeProvider';
import globe_dark from './globe_dark.svg';
import globe_light from './globe_light.svg';
import search_dark from './search_dark.svg';
import search_light from './search_light.svg';
import hamburger_dark from './hamburger-dark.svg'
import hamburger_light from './hamburger-light.svg'

// Define the Icons component
const Icons = () => {
  // Access the theme from the context
  const { theme } = useContext(ThemeContext);

  // Define dark and light icons objects
  const darkIcons = {
    globe: globe_dark,
    search: search_dark,
    hamburger: hamburger_dark
  };

  const lightIcons = {
    globe: globe_light,
    search: search_light,
    hamburger: hamburger_light
  };

  // Return the icons based on the current theme
  return theme === 'dark' ? lightIcons : darkIcons;
};

// Export the Icons component
export default Icons;
