import React from "react";
import PageLayout from "../../layouts/PageLayout";
import "./ExplorePage.css";
import CommonStyle from "../../styles/CommonStyle";
import Images from "../../assets/images/images";
import ExploreCategory from "../../components/ExploreCategory/ExploreCategory";


const ExplorePage = () => {
  const commonStyle = CommonStyle();
  const { heroImg, logoBg } = Images();

  return (
    <PageLayout>
      <div className="explorePage">
        <div
          style={{ backgroundImage: `url(${logoBg})` }}
          className="explorePageAboutSection d-flex center-center"
        >
          <span style={commonStyle.textPrimary} className="font-8 text-center op9">BlogSoFar: Journey through blogs, Where Stories Unfold</span>
          <span style={commonStyle.textPrimary} className="font-5 text-center mt-16 op9">Embark on an exciting journey through our platform where you can
            explore diverse blogs, express your thoughts freely, connect with
            like-minded individuals, and share your unique stories with the
            world. Join us today!</span>
            <span style={commonStyle.textPrimary} className="font-9 mt-18 text-bold">Starting Soon</span>
        </div>
{/* 
        <div  style={commonStyle.headerBackgroud} className="explorePageCategories">
          <ExploreCategory />
        </div> */}
      </div>
    </PageLayout>
  );
};

export default ExplorePage;









// BlogSoFar: Journey through blogs, Where Stories Unfold















