// themes.js - Create a file to define your light and dark mode colors
export const lightColors = {
    color_primary: '#FFFFFF',
    color_primary200: 'rgb(255, 255, 255, 0.2)',
    color_secondary: '#000000',
   
  };
  
  export const darkColors = {
    color_primary: '#000000',
    color_primary200: 'rgb(0, 0, 0, 0.3)',
    color_secondary: '#FFFFFF',
  };
  
  export const themes = {
    light: lightColors,
    dark: darkColors,
  };
  