import { useContext } from 'react';
import { ThemeContext } from '../../theme/ThemeProvider';
import logoDark from './logo_dark.svg'
import logoLight from './logo_light.svg'
import logoBgDark from './logo_bg_dark.svg'
import logoBgLight from './logo_bg_light.svg'
import heroImgDark from './hero_img_dark.jpg'
import heroImgLight from './hero_img_light.jpg'

// Define the Icons component
const Images = () => {
    // Access the theme from the context
    const { theme } = useContext(ThemeContext);

    // Define dark and light icons objects
    const darkImages = {
        logo: logoLight,
        heroImg: heroImgDark,
        logoBg: logoBgLight
    };

    const lightImages = {
        logo: logoDark,
        heroImg: heroImgLight,
        logoBg: logoBgDark
    };

    // Return the icons based on the current theme
    return theme === 'dark' ? darkImages : lightImages;
};

// Export the Icons component
export default Images;
