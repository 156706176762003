import React, { useState, useEffect } from 'react';
import CommonStyle from '../../styles/CommonStyle';
import './Header.css';
import Button from '../Button/Button';
import icons from '../../assets/icons/icons';
import Images from '../../assets/images/images';

const Header = () => {
    const commonStyle = CommonStyle();
    const { globe, search, hamburger } = icons();
    const { logo } = Images();
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };
        // Add event listener for scroll
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className='mainpage'>
            <div className='headerMain' style={scrolled ? { backgroundColor: commonStyle.bgPrimary.backgroundColor } : null}>
                <div className='headerLeft'>
                    <img style={{ width: '100%', height: '100%' }} src={logo} alt="Example SVG" />
                </div>

                {/* <div className='headerCenter col-lg-3 col-4 font-2' style={commonStyle.textPrimary}>
                    <span>Home</span>
                    <span>Explore</span>
                    <span>Categories</span>
                    <span>Write</span>
                </div>

                <div className='headerRight col-lg-3 col-4' style={commonStyle.textPrimary}>
                    <div className="navSearch d-flex center-center">
                        <img src={search} alt="" />
                        <span className='font-2'>search</span>
                    </div>
                    <div className='navLanguage d-flex center-center'>
                        <img src={globe} alt="" />
                        <span className='font-2'>eng</span>
                    </div>
                    <Button />
                </div>

                <div className='headerHamburger d-flex center-center'>
                    <img src={hamburger} alt="" />
                </div> */}
            </div>
        </div>
    );
};

export default Header;
