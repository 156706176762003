import React, { createContext, useEffect, useState } from 'react';
import { themes } from './themes';

export const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(getInitialTheme());

  useEffect(() => {
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (event) => setTheme(event.matches ? 'dark' : 'light');

    prefersDarkMode.addEventListener('change', handleChange);
    return () => prefersDarkMode.removeEventListener('change', handleChange);
  }, []);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  const themeContextValue = {
    theme,
    toggleTheme,
    colors: themes[theme]
  };

  return (
    <ThemeContext.Provider value={themeContextValue}>
      {children}
    </ThemeContext.Provider>
  );
}

// Helper function to get the initial theme based on system preference
const getInitialTheme = () => {
  const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)');
  return prefersDarkMode.matches ? 'dark' : 'light';
};

export default ThemeProvider;
