import React from 'react'
import Header from '../components/Header/Header'
import CommonStyle from '../styles/CommonStyle'
import './PageLayout.css'
import Footer from '../components/Footer/Footer'

const PageLayout = ({ children }) => {
  const commonStyle = CommonStyle();
  return (
    <div className='PageLayout' style={commonStyle.bgPrimary}>
      <Header />
      <div style={{maxWidth: '1440px', margin: 'auto'}}>
      {children}
      </div>
      {/* <Footer/> */}
    </div>

  )
}

export default PageLayout